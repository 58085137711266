<template>
  <div class="center">
    <button
       @click.prevent="openRestoreModal"
       class="btn btn-default btn-with-icon"
    >
      <div class="btn-with-icon-content" title="Restore"><i class="fa fa-undo"></i></div>
    </button>
    <button
       @click.prevent="openDeleteModal"
       class="btn btn-default btn-with-icon"
    >
      <div class="btn-with-icon-content" title="Delete"><i class="fa fa-trash text-danger"></i></div>
    </button>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested', 'xprops'],
  methods : {
    openRestoreModal() {
      this.xprops.eventbus.$emit('openRestoreModal', this.row);
    },

    openDeleteModal() {
      this.xprops.eventbus.$emit('openDeleteModal', this.row);
    },
  }
}
</script>
<style>
  .btn {
    color: #3578c6
  }
</style>
