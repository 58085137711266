<template>
  <vuestic-widget :loading="loading">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">Business Accounts<span class="inst_av">( Archived ) </span></div>
        <div class="d-flex justify-content-end align-items-center">
          <div>
            <b-nav pills>
              <b-nav-item><router-link :to="{name: 'businessAccountsActive'}">Active</router-link></b-nav-item>
              <b-nav-item active><router-link :to="{name: 'businessAccountsArchived'}">Archived</router-link></b-nav-item>
            </b-nav>
          </div>
          <div class="searchForm pl-2">
            <text-input name="search" v-model="search" placeholder="Search..." style="width: 250px"></text-input>
            <button class="btn btn-default btn-primary btn-sm ml-2" @click="handleSearch()" :disabled="!search">
              <i class="fa fa-search"></i> Filter
            </button>
            <button class="btn btn-default btn-primary btn-sm ml-2" @click="clearSearch()">
              Reset
            </button>
          </div>
        </div>
      </div>
    </template>
    <datatable v-bind="$data" class="le-datatable" />

    <vuestic-modal
      :isOpen="isOpenModalRestore"
      @ok="handleRestoreBusiness"
      @cancel="closeModalRestore"
      okText="Restore"
      cancelText="Cancel"
    >
      <span slot="title">Restore Business</span>

      <div>Confirm you want to restore business for {{modalRestoreBusiness.name}}</div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalDelete"
      @ok="handleDeleteBusiness"
      @cancel="closeModalDelete"
      okText="Delete"
      cancelText="Cancel"
      okClass="btn btn-danger"
    >
      <span slot="title" class="text-danger font-weight-bold">Delete Business</span>

      <div>Confirm you want to delete the business for <b>{{modalDeleteBusiness.name}}</b>?</div>

      <div>The business will be permanently deleted.</div>
    </vuestic-modal>

  </vuestic-widget>
</template>

<script>
import components from "../../common/tables/comps";
import MoreDropdown from './Actions/MoreDropdown'
import ActionsArchived from "./Actions/ActionsArchived";

export default {
  created () {
    this.xprops.eventbus.$on('openRestoreModal', row => {
      this.openModalRestore(row);
    })

    this.xprops.eventbus.$on('openDeleteModal', row => {
      this.openModalDelete(row)
    })

  },
  data() {
    return {
      loading: false,
      isOpenModalRestore: false,
      modalRestoreBusiness: {},
      isOpenModalDelete: false,
      modalDeleteBusiness: {},
      supportBackup: false,
      supportNested: false,
      HeaderSettings: false,
      tblClass: "table-bordered",
      tblStyle: "color: #666",
      pageSizeOptions: [10, 25, 50, 100],
      columns: (() => {
        const cols = [
          {
            title: 'ID',
            field: 'id',
            label: 'Business ID',
            sortable: true,
            visible: true
          },
          {
            title: 'Business Name',
            sortable: true,
            field: 'name',
            tdStyle: { fontStyle: 'normal' }
          },
          {
            title: 'Total Installs',
            field: 'count_active_installs',
            sortable: true,
            visible: true,
            tdClass: 'center'
          },
          {
            title: "Operation",
            tdComp: ActionsArchived,
            visible: "true",
            tdClass: "center"
          }
        ];
        return cols;
      })(),
      data: [],
      total: 0,
      // selection: [],  //if this is present the row selector shows up..
      summary: {},
      query: {
        search: null,
      },
      search: null,
      // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
      xprops: {
        eventbus: new Vue()
      }
    };
  },
  watch: {
    query: {
      handler() {
        this.handleQueryChange();
      },
      deep: true
    }
  },
  methods: {
    handleSearch () { 
      this.query.search = this.search
    },

    clearSearch () {
      this.search = null
      this.query.search = null
      this.handleQueryChange()
    },

    openModalDelete (business) {
      this.isOpenModalDelete = true
      this.modalDeleteBusiness = business
    },

    closeModalDelete () {
      this.isOpenModalDelete = false
      this.modalDeleteBusiness = {}
    },

    handleDeleteBusiness () {
      this.loading = true
      const id = this.modalDeleteBusiness.id
      this.closeModalDelete()
      this.$api.deleteBusiness(id).then(({ data }) => {
        if (data.success) {
          const matchedIndex = this.$store.state.sidebarUserList.filter((data, index) => {
            if (data.id !== id) {
              return index
            }
          })
          this.$store.state.sidebarUserList.splice(matchedIndex, 1)
          Vue.$toast.open({
            message: data.data.message,
            type: 'success',
          })
          this.handleQueryChange()
          this.loadInstallStats()
        } else {
          Vue.$toast.open({
            message: data.data.message,
            type: 'error',
          })
        }
        this.loading = false
      }).catch(e => {
        console.warn(e)
        this.loading = false
      })
    },

    handleQueryChange() {
      this.data = []
      this.total = 0;
      this.loading = true;
      const query = {...this.query, status: 'archived'};
      this.$store.dispatch("business/list", query).then(({ data, meta }) => {
        this.data = data;
        this.total = meta.total;
        this.loading = false;
      });
    },
    handleRestoreBusiness() {
      this.loading = true;
      const id = this.modalRestoreBusiness.id;
      this.closeModalRestore();
      this.$api.restoreBusiness(id).then(({data}) => {
        if(data.success) {
          this.showToast(data.data.message);
          this.handleQueryChange();
        } else {
          this.showToast(data.data.message);
        }
        this.loading = false;
      }).catch(e => {
        console.warn(e);
        this.loading = false;
      });
    },
    openModalRestore(business) {
      this.isOpenModalRestore = true;
      this.modalRestoreBusiness = business;
    },
    closeModalRestore() {
      this.isOpenModalRestore = false;
      this.modalRestoreBusiness = {};
    }
  }
};
</script>

<style>
</style>
